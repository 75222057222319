input:focus { 
  outline: 1px solid #2684ff;
  border: 1px solid #2684ff;
}
textarea:focus { 
  outline: 1px solid #2684ff;
  border: 1px solid #2684ff;
}

.rdtDay {
  cursor: pointer;
}

.rdtDay.available-day {
  background-color: #d4edda !important;
  color: #155724 !important;
}

.rdtDay.rdtDisabled {
  background-color: #f8f9fa !important;
  color: #999999 !important;
  cursor: not-allowed;
}

.rdtDay:hover:not(.rdtDisabled) {
  background-color: #28a745 !important;
  color: white !important;
}

.arrow-disclaimer-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos horizontalmente */
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center; /* Centra los iconos dentro del contenedor de la flecha */
}

.disclaimer {
  margin-top: 8px; /* Ajusta el margen según sea necesario */
  display: block;
  color: #8a8a8b;
  font-size: 1.3rem;
  font-weight: 500;
}



